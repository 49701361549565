import dynamic from 'next/dynamic';
const AppLayout = dynamic(() => import('../../components/AppLayout'));
const Spacer = dynamic(() => import('../../components/Content/Spacer'));
const Banner = dynamic(() => import('./UpdatedHomePage'));
const DesignHomeOffice = dynamic(() => import('./DesignHomeOffice'));
const StepsSection = dynamic(() => import('../Home/StepsSection'));
const GetStarted = dynamic(() => import('./GetStarted'));
const ProductsAndServices = dynamic(() => import('./ProductsAndServices'));
const ReviewsSection = dynamic(() => import('./Reviews'));
const MetaCommon = dynamic(() => import('../../components/Meta/MetaCommon'));
import ContactForm from '../CabinetsPage/About/Schedule/ScheduleForm';
import Typography from '../../components/Typography';
import VirtualSection from './VirtualTour';
import WhoWeWorkWith from './WhoWeWorkWith';
import MissionStatement from './MissionStatement';

const ResponsiveVideo = dynamic(() => import('./VideoContainer'), { ssr: false });

export default function ClosetsHomePage() {
  return (
    <>
      <MetaCommon metaDataKey="home" />
      <AppLayout>
        <Banner />
        <ContactForm
          variant="large"
          title="Complimentary Design Consultation & Estimate"
          formId="top-home-page"
        />
        <Spacer size="spacing_big" />
        <Spacer size="spacing_m" />
        <ProductsAndServices />
        <Spacer size="spacing_m" />
        <StepsSection />
        <Spacer size="spacing_big" />
        <Typography
          style={{ letterSpacing: "0.1rem", padding: "1.5rem" }}
          id="why-choose-classy-closets"
          textAlign="center"
          fontSize="2rem"
          fontFamily="Lora Bold"
          color="#762738"
        >
          Why Choose Classy Closets
        </Typography>
        <MissionStatement />
        <ResponsiveVideo />
        <Spacer size="spacing_m" />
        <Spacer size="spacing_xs" />
        <ReviewsSection />
        <DesignHomeOffice />
        <VirtualSection />
        <Spacer size="spacing_m" />
        <WhoWeWorkWith />
        <GetStarted formId={"get-started-home-page"} />
      </AppLayout>
    </>
  );
}
